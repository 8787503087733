export const MENUS = [
  {
    id: 'wonjin',
    label: '원진 소개',
  },
  {
    id: 'core-competency',
    label: '핵심 역량',
  },
  {
    id: 'center',
    label: '센터 소개',
  },
  {
    id: 'service',
    label: '서비스 소개',
  },
  {
    id: 'contact',
    label: '문의하기',
  },
]
